import React from "react";
import "../../styles/general.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import Header from "./header/Header";
import MenuBar from "./menubar/MenuBar";
import Footer from "./footer/Footer";

export default function Layout({children}) {
    return (
        <div className="main-container">
            <Header/>
            <MenuBar/>
            {children}
            <Footer/>
        </div>
    )
}