import React from "react";
import * as styles from "./Footer.module.css";

export default function Footer() {
    return (
        <div className={styles.footerWrapper}>
            <div/>
            <div className={styles.copyright}>
                Copyright 2021 | Tran Anh Nhan
            </div>
            <div className={styles.contactSection}>
                <a href="mailto:nhantran94@gmail.com">
                    <i className={"bi bi-envelope " + styles.contactIcon}/>
                </a>
                <a href="https://www.linkedin.com/in/nhan-tran-8a8b8379/" target="_blank" rel="noreferrer">
                    <i className={"bi bi-linkedin " + styles.contactIcon}/>
                </a>
                <a href="https://zalo.me/nhanatran">
                    <img src="/zalo.svg" alt="" width={20} height={20}/>
                </a>
            </div>
        </div>
    )
}