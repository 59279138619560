import * as React from "react";
import {Helmet} from "react-helmet";

export default function Meta({title, desc}) {
    return (
        <Helmet>
            <title>{title} | Tran Anh Nhan</title>
            <meta name="description" content=""/>
            <meta property="og:type" content="website"/>
            <meta name="og:title" property="og:title" content=""/>
            <meta name="og:description" property="og:description" content=""/>
            <meta property="og:site_name" content=""/>
            <meta property="og:url" content=""/>
            <meta name="twitter:card" content="summary"/>
            <meta name="twitter:title" content=""/>
            <meta name="twitter:description" content={desc}/>
            <meta name="twitter:site" content=""/>
            <meta name="twitter:creator" content=""/>
            <meta property="og:image" content=""/>
            <meta name="twitter:image" content=""/>
            <link rel="preconnect" href="https://fonts.gstatic.com"/>
            <link href="https://fonts.googleapis.com/css2?family=Open+Sans&display=swap" rel="stylesheet"/>
        </Helmet>
    )
}