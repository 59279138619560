import React from "react";
import * as styles from "./Header.module.css";

export default function Header() {
    return (
        <div className={styles.headerContainer}>
            <div className={styles.headerWrapper}>
                <div className={styles.innerWrapper}>
                    <div className={styles.innerContent}>
                        <div className={styles.nameWrapper + " text--outlined"}>
                            TRAN ANH NHAN
                        </div>
                        <div className={styles.infoWrapper}>
                            <div className={styles.infoTopSection}>
                                <div className={styles.infoTitle}>
                                    Frontend Developer
                                </div>
                            </div>
                            <div className={styles.infoBottomSection}>
                                <span className={styles.callToAction}>Contact me</span>
                                <a href="mailto:nhantran94@gmail.com">nhantran94@gmail.com</a>
                                <a href="https://www.linkedin.com/in/nhan-tran-8a8b8379/"
                                   target="_blank" rel="noreferrer">
                                    LinkedIn
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}