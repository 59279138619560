import React from "react";
import {Link} from "gatsby";
import {PathNames} from "../../../utils/constants";
import * as styles from "./MenuBar.module.css";

export default function MenuBar() {
    return (
        <div className={styles.menuBarWrapper}>
            <div className={styles.menuBarContent}>
                {Object.keys(PathNames).map(name => (
                    <Link to={PathNames[name]} className={styles.menuItem} activeClassName={styles.activeItem}
                          key={name}>
                        {name}
                    </Link>
                ))}
            </div>
        </div>
    )
}